const routes = [
    { path: '/' },
    { path: '/schoolhistory' },
    { path: '/visionmissin' },
    { path: '/teachers' },
    { path: '/salientfeatures' },
    { path: '/campus' },
    { path: '/onlineadmission' },
    { path: '/offlineadmission' },
    { path: '/documents' },
    { path: '/bankers' },
    { path: '/rules' },
    { path: '/curriculum' },
    { path: '/assessments' },
    { path: '/indoor' },
    { path: '/academics' },
    { path: '/outdoor' },
    { path: '/clubs' },
    { path: '/studentscorner' },
    { path: '/news' },
    { path: '/events' },
    { path: '/holiday' },
    { path: '/test' },
    { path: '/calendar' },
    { path: '/faq' },
    { path: '/gallery' },
    { path: '/testimonials' },
    { path: '/career' },
    { path: '/contact' },
    { path: '/blogs'},
    { path: '/blog/:url'},
    
];

module.exports = routes; 
