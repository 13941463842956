import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Blogs.module.css'
import preschool from '../../../Assets/Gallery/1.webp'
import preschool2 from '../../../Assets/Gallery/2.webp'
import preschool3 from '../../../Assets/Gallery/24.webp'

const blogs = [
  {
    id: 1,
    title: "Best Preschool in Pune: 5 Things Every Parent Should Know",
    description: "Choosing the best preschool for your child is one of the most important decisions you'll make......",
    url: "best-preschool-in-pune-5-tips-for-parents",
    imageUrl: preschool,
  },
  {
    id: 2,
    title: "10 Easy Preschool Activities to Encourage Creativity and Learning",
    description: "Discover 10 simple and fun preschool activities using everyday items to foster creativity, learning......",
    url: "10-easy-preschool-activities",
    imageUrl: preschool2,
  },
  {
    id: 3,
    title: "10 Key Tips for CBSE Students Facing 10th Board Exams from Hillgreen High School",
    description: "As a student at Hillgreen High School Undri, Pune, your 10th Board Exams mark a significant milestone in your academic journey.......",
    url: "10-Tips-for-CBSE-Students-Facing-10th-Board-Exams",
    imageUrl: preschool3,
  }
]

const Blogs = () => {
  return (
    <div className={styles.blogList}>
      <div className={styles.blogCards}>
        {blogs.map((blog) => (
          <div className={styles.blogCard} key={blog.id}>
            <img src={blog.imageUrl} alt={blog.title} className={styles.blogImage} />
            <h2 className={styles.blogTitle}>{blog.title}</h2>
            <p className={styles.blogDescription}>{blog.description}</p>
            <Link to={`/blog/${blog.url}`} className={styles.readMore}>
              Read More
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Blogs
