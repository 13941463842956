import React, { useEffect, useRef, useState } from 'react'
import styles from './Navbar.module.css'
import { Link } from 'react-router-dom'

// Impost other files
import Logo from '../../Assets/Navbar/Logo.webp'


const Navbar = () => {
    const menuRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [aboutDropdown, setAboutDropdown] = useState(false);
    const [AdmissionDrop, setAdmissionDrop] = useState(false);
    const [learning, setlearning] = useState(false);
    const [activities, setactivities] = useState(false);
    const [what, setwhat] = useState(false);
    const [mandatory, setMandatory] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const aboutToggle = () => {
        setAboutDropdown(!aboutDropdown);
        setAdmissionDrop(false);
        setlearning(false);
        setactivities(false);
        setwhat(false);
        setMandatory(false);
    };
    const admissionToggle = () => {
        setAdmissionDrop(!AdmissionDrop);
        setAboutDropdown(false);
        setlearning(false);
        setactivities(false);
        setwhat(false);
        setMandatory(false);
    }
    const learningToggle = () => {
        setlearning(!learning);
        setAboutDropdown(false);
        setAdmissionDrop(false);
        setactivities(false);
        setwhat(false);
        setMandatory(false);

    }
    const activitiesTogle = () => {
        setactivities(!activities);
        setAboutDropdown(false);
        setAdmissionDrop(false);
        setlearning(false);
        setwhat(false);
        setMandatory(false);
    }

    const whatToggle = () => {
        setwhat(!what);
        setAboutDropdown(false);
        setAdmissionDrop(false);
        setactivities(false);
        setlearning(false);
        setMandatory(false);
    }

    const mandatoryToggle = () => {
        setMandatory(!mandatory);
        setwhat(false);
        setAboutDropdown(false);
        setAdmissionDrop(false);
        setactivities(false);
        setlearning(false);
    }

    const close = () => {
        setIsMenuOpen(false);

    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                menuRef.current &&
                !menuRef.current.contains(event.target) &&
                !event.target.classList.contains(styles.bars)
            ) {
                setIsMenuOpen(false);
                setAboutDropdown(false);
                setAdmissionDrop(false);
                setlearning(false);
                setactivities(false);
                setwhat(false);

            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const onButtonClick = () => {
        const pdfUrl = "Admissionform.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Admissionform.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const mandotoryDownload = () => {
        const pdfUrl = 'Mandatorydisclosure.pdf';
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Mandatorydisclosure.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const ptaMemberslist = () => {
        const pdfUrl = 'ptaMemberslist.pdf';
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "ptaMemberslist.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
    }
  

    const Smclist = () => {
        const pdfUrl = 'Smclist.pdf';
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Smclist.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
    }  
 
    const Feestructure = () => {
        const pdfUrl = 'Feestructure.pdf';
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Feestructure.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
    }

    const Results = () => {
        const pdfUrl = 'Results.pdf';
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Results.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
    }
    const SEXUALHARASSEMENT = () => {
        const pdfUrl = 'SEXUAL_HARASSEMENT.pdf';
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "SEXUAL_HARASSEMENT.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
    }


    const POCSO = () => {
        const pdfUrl = 'POCSO.pdf';
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "POCSO.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
    }

    return (
        <>
            <header>
                <div className={styles.top_bar}>
                    <div className={styles.tob_bar_inner}>
                        <div className={styles.item1}>
                            <div className={styles.margin}>
                                <a href="mailto:hghs_pune@yahoo.co.in"><i class="fa-solid fa-envelope"></i></a>
                            </div>
                            <div className={styles.margin}>
                                <a href="mailto:hghs_pune@yahoo.co.in"><p>hghs_pune@yahoo.co.in</p></a>
                            </div>
                        </div>
                        <div className={styles.item2}>
                            <div className={styles.margin}>
                                <a href="tel:+919325980847"><i class="fa-solid fa-phone"></i></a>
                            </div>
                            <div className={styles.margin}>
                                <a href="tel:+919325980847">+91 73919 17181</a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.tob_bar_inner}>
                    <a href='Admissionform.pdf' onClick={onButtonClick} download>
                            <button className={styles.download_form_button}>Download Application Form</button>
                        </a>
                    </div>
                </div>

            </header>
            <div className={styles.nav_wrapper}>
                <div className={styles.navbar}>
                    <div className={styles.logo}>
                        <img src={Logo} alt='Hillgreen School logo'/>
                        <p>Hillgreen High School <br /> & Jr. College</p>
                    </div>
                    <ul ref={menuRef} className={`${styles.nav_links} ${isMenuOpen ? styles.main_menu_open : styles.main_menu_close}`}>
                        <Link to='/' className={styles.links} onClick={close}>Home</Link>

                        <Link className={styles.links} onClick={aboutToggle}>
                            About Us <i class="fa-solid fa-angle-down"></i>
                            <div className={`${styles.aboutDropdown} ${aboutDropdown ? styles.aboutDropdownopen : ''} `}>
                                <Link to='/schoolhistory' className={styles.links} onClick={close}> <li>School History</li></Link>
                                <Link to='/visionmissin' className={styles.links} onClick={close}><li>Vision & Mission</li></Link>
                                <Link to='/teachers' className={styles.links} onClick={close}><li>Teachers</li></Link>
                                <Link to='/salientfeatures' className={styles.links} onClick={close}><li>Salient Fetures</li></Link>
                                <Link to='/campus' className={styles.links} onClick={close}><li>Campus</li></Link>
                            </div>
                        </Link>

                        <Link className={styles.links} onClick={admissionToggle}>
                            Admission <i class="fa-solid fa-angle-down"></i>
                            <div className={`${styles.admissionDropdown} ${AdmissionDrop ? styles.admissionDropdownOpen : ''}`}>
                                <Link to='/onlineadmission' className={styles.links} onClick={close}><li>Online Admission</li></Link>
                                <Link to='/offlineadmission' className={styles.links} onClick={close}><li>Offline Admission</li></Link>
                                <Link to='/documents' className={styles.links} onClick={close}><li>Documents</li></Link>
                                <Link to='/bankers' className={styles.links} onClick={close}> <li>Our Bankers</li> </Link>
                            </div>
                        </Link>
 
                        <Link className={styles.links} onClick={learningToggle}>
                            Learning <i class="fa-solid fa-angle-down"></i>
                            <div className={`${styles.learningDropdown} ${learning ? styles.learningDropdownOpen : ''}`}>
                                <Link to='/rules' className={styles.links} onClick={close}> <li>Rules & Regulation</li></Link>
                                <Link to='/curriculum' className={styles.links} onClick={close}> <li>Curriculum</li></Link>
                                <Link to='/assessments' className={styles.links} onClick={close}><li>Assessments</li></Link>
                                <Link to='/academics' className={styles.links} onClick={close}><li>Academics</li></Link>
                                <Link to='/faq' className={styles.links}>FAQ</Link>
                            </div>
                        </Link>

                        <Link className={styles.links} onClick={activitiesTogle}>
                            Activities <i class="fa-solid fa-angle-down"></i>
                            <div className={`${styles.activitiesDropdown} ${activities ? styles.activitiesDropdownOpen : ''}`}>
                                <Link to='/indoor' className={styles.links} onClick={close}><li>Indoor</li></Link>
                                <Link to='/outdoor' className={styles.links} onClick={close}><li>Outdoor</li></Link>
                                <Link to='/clubs' className={styles.links} onClick={close}><li>Clubs</li></Link>
                                <Link to='/studentscorner' className={styles.links} onClick={close}><li>Student's Corner</li></Link>
                                {/* <Link to='/teacherscorner' className={styles.links} onClick={close}><li>Teacher's Corner</li></Link> */}
                            </div>
                        </Link>

                        <Link className={styles.links} onClick={whatToggle}>
                            What's Happening <i class="fa-solid fa-angle-down"></i>
                            <div className={`${styles.whatDropdown} ${what ? styles.whatDropdownOpen : ''}`}>
                            <Link to='/calendar' className={styles.links} onClick={close}><li>Calendar</li></Link>
                                {/* <Link to='/events' className={styles.links} onClick={close}><li>Events</li></Link> */}
                                <Link to='test' className={styles.links} onClick={close}><li>Test Schedule</li></Link>
                                <Link to='/news' className={styles.links} onClick={close}><li>News</li></Link>
                                <Link to='/blogs' className={styles.links} onClick={close}><li>Blogs</li> </Link>
                            </div>
                        </Link>

                        

                        <Link className={styles.links} onClick={mandatoryToggle}>Mandatory Disclosure
                            <div className={`${styles.mandatoryDropdown} ${mandatory ? styles.mandatoryDropdownOpen : ''}`}>
                                <Link  className={styles.links}><a  href="/Mandatorydisclosure.pdf" onClick={mandotoryDownload} download="Mandatorydisclosure.pdf" style={{ textDecoration: 'none', color: '#5F5F5F'}}>Mandatory Disclosure</a></Link>
                           
                                <Link  className={styles.links}><a  href="/PTA MEMBERS LIST" onClick={ptaMemberslist} download="ptaMemberslist.pdf" style={{ textDecoration: 'none', color: '#5F5F5F'}}>PTA Members List</a></Link>

                                <Link  className={styles.links}><a  href="/Smclist" onClick={Smclist} download="Smclist.pdf" style={{ textDecoration: 'none', color: '#5F5F5F'}}>SMC List</a></Link>

                                <Link  className={styles.links}><a  href="/Feestructure" onClick={Feestructure} download="Feestructure.pdf" style={{ textDecoration: 'none', color: '#5F5F5F'}}>Feestructure</a></Link>

                                <Link  className={styles.links}><a  href="/SEXUAL_HARASSEMENT" onClick={SEXUALHARASSEMENT} download="SEXUAL_HARASSEMENT.pdf" style={{ textDecoration: 'none', color: '#5F5F5F'}}>Sexual Harassement</a></Link>

                                <Link  className={styles.links}><a  href="/POCSO" onClick={POCSO} download="POCSO.pdf" style={{ textDecoration: 'none', color: '#5F5F5F'}}>POCSO</a></Link>

                                <Link  className={styles.links}><a  href="/Results" onClick={Results} download="Feestructure.pdf" style={{ textDecoration: 'none', color: '#5F5F5F'}}>Results</a></Link>
                           
                            </div>
                           

                        </Link>  

                        <Link to='/gallery' className={styles.links}>Gallery</Link>

                        <Link to='/testimonials' className={styles.links} onClick={close}>Testimonials</Link>
                        <Link to='/career' className={styles.links} onClick={close}>Career</Link>
                        <Link to='/contact' className={styles.links} onClick={close}>Contact Us</Link>
                      
                    </ul>

                    <div className={`${styles.bars} ${isMenuOpen ? styles.cross : ''} `} onClick={toggleMenu}>
                        <div className={`${styles.bar} ${styles.bars}`}></div>
                        <div className={`${styles.bar} ${styles.bars}`}></div>
                        <div className={`${styles.bar} ${styles.bars}`}></div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Navbar