import React from 'react';
import styles from './Why.module.css';
import school from '../../../Assets/Home/school.webp';
import ScrollAnimation from 'react-animate-on-scroll';

const Why = () => {
  return (
    <div className={styles.why_hillgreen}>
      <div className={styles.why_flex}>
        <div className={styles.why_box}>
          <ScrollAnimation animateIn='fadeInUp'>
            <div className={styles.why_image_holder}>
              <img src={school} alt='Hillgreen School'/>
            </div>
          </ScrollAnimation>
        </div>
        <div className={styles.why_box}>
          <ScrollAnimation animateIn='fadeInUp' delay={200}>
            <div className={styles.why_content}>
              <h2>To Make A Bright Future</h2>
              <h3>Change In Education! Need Of The Hour</h3>
              <p>The future is entirely a new kind of world with new kind of needs. There are new types of Jobs to address these changing needs. The needs for new kind of competencies arise to suit these new jobs. We require an effective alternate system of education.</p>
            </div>
          </ScrollAnimation>
        </div>
      </div>
      <div className={styles.why_we_are}>
        <div className={styles.why_we_are_head}>
          <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutLeft'>
            <h2>Why Choose Hillgreen High School?</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' delay={200}>
            <p>Discover the best CBSE, SSC School and Junior College in Undri, Pune, where we help you see the world differently, unlock opportunities you never imagined, and achieve results that bridge the gap between where you are and where you can be.</p>
          </ScrollAnimation>
        </div>
        <div className={styles.why_we_are_main}>
          <div className={styles.wrapper}>
            <div className={`${styles.two} ${styles.grid_box}`}>
              <ScrollAnimation animateIn='fadeInUp' delay={200}>
                <div className={styles.triangle_topright}></div>
                <h2 style={{color: 'red'}}>Exceptional Academic Results:</h2>
                <p>Consistently high performance in CBSE 10th and 12th board exams. <br/> Strong foundation for higher education and career success. </p>
                
                <h2 style={{color: 'red'}}>Comprehensive Curriculum:</h2>
                <p>Balanced focus on academic rigor, sports, arts, and personality development. <br/> A specially tailored program for Primary and Nursery school students that supports early learning.</p>
              </ScrollAnimation>
            </div>
            <div className={`${styles.three} ${styles.grid_box}`}></div>
            <div className={`${styles.five} ${styles.grid_box}`}>
              <ScrollAnimation animateIn='fadeInUp' delay={200}>
                <div className={styles.triangle_topright}></div>
                <h2 style={{color: 'red'}}>Outstanding Faculty:</h2>
                <p>Experienced and CBSE-certified educators passionate about student success. <br/>Individualized attention to unlock every child's true potential. </p>
                <h2 style={{color: 'red'}}>Modern Infrastructure:</h2>
                <p>State-of-the-art classrooms, libraries, and labs. <br/>Dedicated play areas and activity zones for holistic development. </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Why;
