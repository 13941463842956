import React from 'react';
import styles from './Absolute.module.css';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

const Absolute = () => {
  return (
    <>
      <section className={styles.absolute_container} aria-label="Highlights">
        <div className={styles.absolute_content}>
          <div className={styles.absolute_content_top}>
            <div className={styles.grid_box}>
              <div className={styles.grid_box_content}>
                <div className={styles.flex}>
                  <div className={styles.icon}>
                    <i className="fa-solid fa-school" aria-hidden="true"></i>
                  </div>
                  <div className={styles.text_matter}>
                    <h3>EDUCATION</h3>
                    <p>Providing Best Education For Brighter future</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.grid_box}>
              <div className={styles.grid_box_content}>
                <div className={styles.flex}>
                  <div className={styles.icon}>
                    <i className="fa-solid fa-user-tie" aria-hidden="true"></i>
                  </div>
                  <div className={styles.text_matter}>
                    <h3>PROFESSIONAL</h3>
                    <p>Our Staff is educated specifically in methods of teaching</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.grid_box}>
              <div className={styles.grid_box_content}>
                <div className={styles.flex}>
                  <div className={styles.icon}>
                    <i className="fa-solid fa-book" aria-hidden="true"></i>
                  </div>
                  <div className={styles.text_matter}>
                    <h3>RESEARCH</h3>
                    <p>Give the power to enhance learning skills</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.grid_box}>
              <div className={styles.grid_box_content}>
                <div className={styles.flex}>
                  <div className={styles.icon}>
                    <i className="fa-solid fa-users" aria-hidden="true"></i>
                  </div>
                  <div className={styles.text_matter}>
                    <h3>MANAGEMENT</h3>
                    <p>Running the school along the desired educational policies</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ScrollAnimation animateIn="fadeIn">
            <div className={styles.absolute_bottom}>
              <div className={styles.absolute_bottom_flex}>
                <div className={styles.bottom_image_container}></div>
                <div className={styles.bottom_content}>
                  <div className={styles.overlay}></div>
                  <div className={styles.margin_auto}>
                    <h1>Welcome to Hillgreen High School for Best CBSE, SSC & Junior College in Undri, Pune</h1>
                    <h2>Your Partner in Excellence - From Nursery to Junior College</h2>
                    <p>Hillgreen High School & Junior College (HHSJC) is the best CBSE school in Pune dedicated to providing an enriching and holistic education. With over 30 years of excellence, our institution nurtures young minds to excel academically and socially.</p>
                    <Link to="/onlineadmission">
                      <button className={styles.main_button}>Apply Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </section>
    </>
  );
};

export default Absolute;
