import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './BlogDetail.module.css';
import preschool from '../../../Assets/Gallery/1.webp';
import preschool2 from '../../../Assets/Gallery/2.webp';
import preschool3 from '../../../Assets/Gallery/24.webp'

const blogs = [
  {
    id: 1,
    title: "Best Preschool in Pune: 5 Essential Tips for Parents | Hillgreen High School",
    description:
      "Looking for the best preschool in Pune? Discover 5 key factors every parent should consider, including safety, facilities, qualified teachers, and effective communication.",
    url: "best-preschool-in-pune-5-tips-for-parents",
    content: [
      { type: 'paragraph', text: "Choosing the best preschool for your child is one of the most important decisions you’ll make. It sets the foundation for their education, social development, and emotional well-being. With so many options available in Pune, it can be overwhelming for parents to make the right choice. " },
      { type: 'heading', text: "The 5 essential factors you should consider when choosing the perfect preschool for your child:-" },

      { type: 'heading', text: "1. Facilities That Support Growth" },
      { type: 'paragraph', text: "A preschool’s facilities should foster your child’s overall development. Look for classrooms that are bright, spacious, and well-equipped with age-appropriate learning tools. Check the outdoor play areas to ensure they are safe and spacious for physical activities. Cleanliness and hygiene should be top priorities; check washrooms, toys, and play equipment for proper maintenance." },
      { type: 'heading', text: "2. Qualified and Caring Faculty" },
      { type: 'paragraph', text: "Your child’s preschool teachers will play a significant role in shaping their early experiences. Ensure that the teachers are well-qualified, trained in early childhood education, and equipped with the skills to manage and nurture young children. It’s crucial that they provide a supportive, encouraging, and engaging environment." },
      { type: 'heading', text: "3. Safety First" },
      { type: 'paragraph', text: "While modern facilities and engaging curricula are essential, nothing should be more important than your child’s safety. Before selecting a preschool, ensure that it follows strict safety protocols, such as secure entry and exit points, CCTV surveillance, and proper emergency response procedures." },
      { type: 'heading', text: "4. Effective Communication with Parents" },
      { type: 'paragraph', text: "A good preschool will maintain open lines of communication with parents, providing regular updates on your child’s learning journey. Look for schools that offer regular parent-teacher meetings, progress reports, and a dedicated staff member for addressing concerns." },
      { type: 'heading', text: "5. Student-to-Teacher Ratio" },
      { type: 'paragraph', text: "In the early years, children thrive on personal attention. A low student-to-teacher ratio ensures that your child receives the care and guidance they need to feel secure and supported. Smaller groups allow teachers to cater to individual learning styles and provide personalized attention." },
      { type: 'paragraph', text: "How to Evaluate Preschools in Pune: Consider these practical tips: Ask for recommendations from other parents, check online reviews, and visit the preschool to assess the environment and interact with the teachers. These steps will help you make an informed decision for your child’s educational journey." },
      { type: 'paragraph', text: "Wrapping Up: Choosing the right preschool in Pune is crucial for your child’s educational and emotional growth. A preschool that offers excellent facilities, experienced teachers, a safe environment, and open communication will lay the foundation for success. Hillgreen High School offers an exceptional early learning program that emphasizes safety, care, and overall development." }
    ],
    imageUrl: preschool,
  },
  {
    id: 2,
    title: "10 Easy Preschool Activities to Encourage Creativity and Learning",
    description:
      "Discover 10 simple and fun preschool activities using everyday items to foster creativity, learning, and motor skills development in young children. Perfect for at-home education!",
    url: "10-easy-preschool-activities",
    content: [
      { type: 'paragraph', text: "Homeschooling young children can be a rewarding yet challenging experience. As parents and caregivers, it’s essential to provide engaging activities that promote learning, creativity, and motor skills development. " },
      { type: 'heading', text: "Here are 10 simple preschool activities that use everyday household items and are designed to support early childhood development:- " },
      { type: 'heading', text: "1. Paper Towel Drip Painting" },
      { type: 'paragraph', text: "This fun art activity introduces young children to color theory and creativity. Simply use a paper towel, watercolor paints, and droppers to create colorful designs. This activity encourages children to work cooperatively, experiment with colors, and develop fine motor skills." },
      { type: 'heading', text: "2. Bubble Wrap Printing" },
      { type: 'paragraph', text: "Turn simple materials like bubble wrap into a fun art project! Using markers or paint, children can color the bubble wrap, press it onto paper, and create textured prints." },
      { type: 'heading', text: "3. Unicorn Fizz" },
      { type: 'paragraph', text: "This exciting science experiment combines chemistry and sensory exploration. By mixing baking soda with vinegar and food coloring, children can watch a “magical” fizzing reaction." },
      { type: 'heading', text: "4. Best Play Dough Recipe Ever" },
      { type: 'paragraph', text: "Homemade play dough is soft, durable, and easy to make! This simple recipe gives children endless opportunities to mold, shape, and create. It's perfect for hands-on learning while building fine motor skills." },
      { type: 'heading', text: "5. Calm Down Bottles" },
      { type: 'paragraph', text: "A calm down bottle filled with glitter or beads provides a soothing activity for children when they need a break. The slow movement of the objects inside encourages mindfulness and relaxation." },
      { type: 'heading', text: "6. Ice Cube Painting" },
      { type: 'paragraph', text: "Use frozen colored ice cubes to paint on paper. As the ice melts, children explore how colors blend and change, making it a sensory-rich experience." },
      { type: 'heading', text: "7. Pet Rocks" },
      { type: 'paragraph', text: "This activity allows children to choose a rock, paint it, and turn it into a pet! Not only is it a creative activity, but it also encourages problem-solving and fine motor skills." },
      { type: 'heading', text: "8. Color Theory Finger Painting" },
      { type: 'paragraph', text: "Children can explore color mixing and create their own masterpiece using finger painting. This activity helps them understand primary and secondary colors." },
      { type: 'heading', text: "9. Picasso Shape Art Project" },
      { type: 'paragraph', text: "Using simple shapes like circles, squares, and triangles, children can create unique pieces of art inspired by Picasso’s abstract style." },
      { type: 'heading', text: "10. Tape Resist Name Art" },
      { type: 'paragraph', text: "Using painter’s tape, children can create their names on paper, paint over the tape, and peel it off to reveal their name in a bold design." },
      { type: 'paragraph', text: "How to Make the Most of These Activities: Always supervise your child during activities, adjust for age, and observe their progress. These activities not only make learning enjoyable but also lay the foundation for future academic and personal growth." },
    ],
    imageUrl: preschool2,
  },

  {
    id: 3,
    title: "10 Key Tips for CBSE Students Facing 10th Board Exams from Hillgreen High School",
    description:
      "Prepare for your 10th Board Exams with confidence! Explore 10 expert tips from Hillgreen High School Undri, Pune, designed to help CBSE students excel.",
    url: "10-Tips-for-CBSE-Students-Facing-10th-Board-Exams",
    content: [
      { type: 'paragraph', text: "As a student at Hillgreen High School Undri, Pune, your 10th Board Exams mark a significant milestone in your academic journey. Your scorecard will not only influence your further studies but also shape your career path. Beyond the guidance of your school and tutors, mastering how to prepare effectively is crucial. Here are tailored tips, reviewed by our education experts, to ensure you're fully equipped for success. " },
      { type: 'heading', text: "Following are the 10 Tips for CBSE Students Facing 10th Board Exams" },
      { type: 'paragraph', text: "1) Maintain Optimism Stay confident with a well-planned strategy. Don’t let anxiety overshadow your preparations." },
      { type: 'paragraph', text: "2) Craft Your Study Schedule Allocate time wisely, considering your strengths, weaknesses, and school/tuition timings. Prioritize subjects needing extra attention." },
      { type: 'paragraph', text: "3) Complete Your Syllabus Ahead of Time Finish your syllabus well before the exams to allow ample time for revision and practicing with previous years’ question papers." },
      { type: 'paragraph', text: "4) Analyze Sample Papers Understand question patterns by solving previous years’ papers, available on School" },
      { type: 'paragraph', text: "5) Learn from Toppers Study solution papers of high achievers to grasp which areas to focus on for better scoring" },
      { type: 'paragraph', text: "6) Focus on Your Pace Avoid comparing your study progress with peers. Concentrate on your goals and study schedule." },
      { type: 'paragraph', text: "7) Regular Breaks for a Refreshed Mind Balance study sessions with breaks to maintain productivity and reduce stress." },
      { type: 'paragraph', text: "8) Prioritize Your Health Adhere to a healthy diet, sleep schedule, and exercise routine to avoid last-minute health issues." },
      { type: 'paragraph', text: "9) Final Month Strategy Create a detailed plan for the last month, ensuring equal focus on all subjects for a balanced score." },
      { type: 'paragraph', text: "10) Mock Tests for Readiness Engage in mock tests to familiarize yourself with the exam environment and identify areas for improvement." },
      
    ],
    imageUrl: preschool3,
  },
];

const BlogDetail = () => {
  const { url } = useParams();
  const blog = blogs.find((blog) => blog.url === url);

  if (!blog) {
    return <h2>Blog not found</h2>;
  }

  return (
    <div className={styles.blogDetail}>
    <Helmet>
      <title>{blog.title}</title>
      <meta name="description" content={blog.description} />
      <meta property="og:title" content={blog.title} />
      <meta property="og:description" content={blog.description} />
      <meta property="og:image" content={blog.imageUrl} />
      <meta property="og:url" content={window.location.href} />
    </Helmet>
    <h1 className={styles.blogTitle}>{blog.title}</h1>
    <img src={blog.imageUrl} alt={blog.title} className={styles.blogImage} />
    <div className={styles.blogContent}>
      {blog.content.map((section, index) => (
        section.type === 'heading' ? (
          <h3 key={index} className={styles.blogHeading}>
            {section.text}
          </h3>
        ) : (
          <p key={index} className={styles.blogParagraph}>
            {section.text}
          </p>
        )
      ))}
    </div>
  </div>
  );
};

export default BlogDetail;
