import React from 'react';
import styles from './Principal.module.css';
import principal from '../../../Assets/Principle.webp';
import Manisir from '../../../Assets/Home/Mani_sir.jpg'

const Principal = () => {
    return (
        <>
        {/* Principal */}
        <section className={styles.principal} aria-labelledby="principal-heading">
            <h2 id="principal-heading" className={styles.heading}>Principal Message</h2>
            <div className={styles.principal_flexbox}>
                <div className={styles.principal_image}>
                    <img src={principal} alt='Principal Mrs. Varsha Phukane' />
                    <h3>Mrs. Varsha Phukane</h3>
                    <p>(PRINCIPAL)</p>
                </div>
                <div className={styles.principal_message}>
                    <div className={styles.principal_message_box}>
                        <h4>Dear Parents and Students,</h4>
                        <h4>Greetings from Hillgreen High School & Jnr. College,</h4>
                        <p>At Hillgreen High School, we believe in creating a supportive, inspiring environment for every student. Our goal is to nurture future leaders who are compassionate, confident and capable of making a difference in the world.</p>
                        <br /> 
                        <p>We offer a wide range of extracurricular activities and support services to ensure that every student can explore their interests and reach their full potential.</p>
                        <p>We value the partnership between home and school and encourage active communication and involvement from parents. Together, we can create a supportive community that empowers our students to excel and become responsible, compassionate global citizens. The institution aims to serve as a lighthouse giving light to all the coming generations and leading the way to excellence. I wish you all the best for the coming years.</p>
                    </div>
                </div>
            </div>
        </section>

        {/* Director */}

        <section className={styles.principal} aria-labelledby="principal-heading">
            <h2 id="principal-heading" className={styles.heading}>From The Manager's Desk </h2>
            <div className={styles.principal_flexbox}>
                <div className={styles.principal_image}>
                    <img src={Manisir} alt='MANAGER Manikandan Kulangara sir' />
                    <h3>Manikandan Kulangara</h3>
                    <p>(MANAGER)</p>
                </div>
                <div className={styles.principal_message}>
                    <div className={styles.principal_message_box}>
                       
                        <p>Hillgreen High School & Jr. College Undri, aims to offer top-notch education under its canopy. Because of this foresight that for the past 30 consecutive years, college name has been able to impart a form of progressive education among students. As an educational institution, HGHS & JC has succeeded in turning students into aspiring professionals. It leaves no stone unturned in the professional and academic skill development of its students with a galaxy of institutes that boast an excellent teaching force, just infrastructure, collaborations, and a paradigm curriculum harmonized with the current situation.</p>
                        <br /> 
                        <p>It has an incredible teaching squad that forms the backbone of the system. The faculty members in HGHS & JC are well qualified and knowledgeable, striving hard to provide all-round education to students by imbibing knowledge to enhance the experience of education. This ensures every student in their classroom marches out with confidence and enthusiasm to achieve their academic goals and a great career ahead.</p>

                        <p>We try to propagate the creativity of the child, providing adequate exposure to enrich their knowledge and spread their wings of imagination to excel in all fields. Our attempt at school is to create the best possible environment for learning, moving towards the attainment of excellence in academics and other areas with a global perspective.</p>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default Principal;
 