import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

// Import your components for each route
import Gototop from './Components/Gototop/Gototop';
import ScrollToTop from './Components/ScrollToTop'
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home'
import SchoolHistory from './Components/About/SchoolHistory/SchoolHistory';
import Visionmission from './Components/About/VisionMission/VisionMission';
import SalientFeatures from './Components/About/SalientFeatures/SalientFeatures';
import OnlineAdmission from './Components/Admission/OnlineAdmission/OnlineAdmission';
import OfflineAdmission from './Components/Admission/OfflineAdmission/OfflineAdmission';
import Documents from './Components/Admission/Documents/Documents';
import Bankers from './Components/Admission/Bankers/Bankers';
import Rules from './Components/Learning/Rules/Rules';
import Curriculum from './Components/Learning/Curriculum/Curriculum';
import Assessments from './Components/Learning/Assessments/Assessments';
import Indoore from './Components/Activities/Indoor/Indoore';
import Academics from './Components/Learning/Academics/Academics';
import Outdoor from './Components/Activities/Outdoor/Outdoor';
import Clubs from './Components/Activities/Clubs/Clubs';
import StudemtsCorner from './Components/Learning/StudentsCorner/StudemtsCorner';
import Footer from './Components/Footer/Footer';
import Campus from './Components/About/Campus/Campus';
import Teachers from './Components/About/Teachers/Teachers';
import Teacherscorner from './Components/Activities/Teacherscorner/Teacherscorner';
import News from './Components/Whatshappening/News/News';
import Events from './Components/Whatshappening/Events/Events';
import Gallery from './Components/Gallery/Gallery';
import Testimonials from './Components/Testimonials/Testimonials';
import Contact from './Components/Contact/Contact';
import Holiday from './Components/Whatshappening/Holiday/Holiday';
import TestSchedule from './Components/Whatshappening/TestSchedule/TestSchedule';
import Calendar from './Components/Whatshappening/Calendar/SchoolCalendar';
import Faq from './Components/Whatshappening/Faq/Faq';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import logo from './Assets/Navbar/Logo.png'
import './App.css'
import Career from './Components/Career/Career';
import Blogs from './Components/Whatshappening/Blogs/Blogs';

// import PopupModal from './Components/PopupModal/PopupModal';
import routes from './routes'
import BlogDetail from './Components/Whatshappening/Blogs/BlogDetail';



const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-SG5BF42KFX', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);

  return null; 
};

const App = () => {
  const components = {
    '/':Home,
    '/schoolhistory':SchoolHistory,
    '/visionmissin':Visionmission,
    '/teachers':Teachers,
    '/salientfeatures':SalientFeatures,
    '/campus' :Campus,
    '/onlineadmission':OnlineAdmission,
    '/offlineadmission':OfflineAdmission,
    '/documents':Documents,
    '/bankers':Bankers,
    '/rules':Rules,
    '/curriculum':Curriculum,
    '/assessments':Assessments,
    '/indoor':Indoore,
    '/academics':Academics,
    '/outdoor':Outdoor,
    '/clubs':Clubs,
    '/studentscorner':StudemtsCorner,
    '/news':News,
    '/events':Events,
    '/holiday':Holiday,
    '/test':TestSchedule,
    '/calendar':Calendar,
    '/blogs': Blogs,
    '/blog/:url':BlogDetail,
    '/faq':Faq,
    '/gallery':Gallery,
    '/testimonials':Testimonials,
    '/career':Career,
    '/contact':Contact,
  };


  return (
    <BrowserRouter basename="/"> {/* basename="/" */}

    <ScrollToTop/>
      <Navbar />
      <PageTracker />
      <Routes>
      {routes.map((route) => (
            <Route key={route.path} path={route.path} element={React.createElement(components[route.path])} />
          ))}
      </Routes > 
      <Gototop/>
      <FloatingWhatsApp 
        avatar={logo}
        phoneNumber="+91 9325980847"
        accountName="Support"
        chatMessage="Hello there! 🤝 How can we help?"
        placeholder="Type a message.."
      />
      {/* <PopupModal/> */}
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
