import React from 'react';
import styles from './Intro.module.css';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

const Intro = () => {
    return (
        <section className={styles.introduction} aria-label="School Introduction">
            <div className={styles.overlay}></div>
            <div className={styles.flex_history}>
                <div className={styles.box}>
                    <ScrollAnimation animateIn='bounceInLeft' animateOut='bounceOutLeft'>
                        <h2>History of the School</h2>
                    </ScrollAnimation>
                </div>

                <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut'>
                    <div className={styles.box}>
                        <p>Established in 1994, Hillgreen High School is a project of the Kerala People's Education Society (KPES), offering affordable, quality education. Our mission is to empower students to thrive in a rapidly changing world by providing a strong academic foundation coupled with values and ethics.
                        </p>
                        
                        <Link to='/schoolhistory' className={styles.main_button}>Read More</Link>
                    </div>
                </ScrollAnimation>
            </div>
        </section>
    );
}

export default Intro;
